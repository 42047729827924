import axios from 'axios'

export default {
    namespaced: true,
    state: {
        staff:{
            name:'',
            userId:'',
            email:'',
            tel:'',
            idCard:'',
            place:'',
            gift:{city:[],addr:'',name:'',tel:''},
            isGift:'',
            isEntourage:'',
            entourageItems:[{name:'', idCard:''}],
        },
        pageTitle:'',
        rankInfo:{
            my_index: '',
            my_record: {name:'',place:'',checkin_time:''},
            rank: []
        },
        rankInfoStep:{
            my_index: '',
            my_record: {name:'',place:'',checkin_time:''},
            rank: []
        },
        checkPoints: [
            {id:'01', name: '朝阳公园', img:require('../image/place_cygy.png'), contact:{name: '冯伟', tel: '18810706650', check_point: '朝阳公园 地铁站D1出口，西南角'}},
            {id:'02', name: '玉渊潭公园', img:require('../image/place_yytgy.png'), contact: {name: '朱鸿竑', tel: '13426306038', check_point: '西门停车场'}},
            {id:'03', name: '万芳亭公园', img:require('../image/place_wftgy.png'), contact: {name: '徐青', tel: '13522866885', check_point: '南2门(网球中心)'}},
            {id:'04', name: '奥林匹克森林公园南园', img:require('../image/place_alpkgy.png'), contact: {name: '乐琪', tel: '13146133431', check_point: '森林公园南门 游客咨询中心'}},
        ]
    },
    getters: {
    },
    actions: {
        selectPlace(ctx,placeId){
            ctx.commit('placeSelected',placeId)
        },
        //页面标题
        pageTitle(ctx,payload){
            ctx.commit('setPageTitle',payload)
        },
        //员工信息
        staffSelfGift(ctx,payload){
            // console.log(payload)
            ctx.commit('staffSelfGiftUpdated',payload)
        },
        //员工号邮箱
        staffUseridEmail(ctx,payload){
            ctx.commit('staffUseridEmailUpdated',payload)
        },
        //随行人员
        staffEntourage(ctx,payload){
            ctx.commit('staffEntourageUpdated',payload)
        },
        //提交信息
        submitInfo(ctx, payload){
            return new Promise((resolve,reject)=>{
                axios.post('/register', payload).then((res)=>{
                    // console.log(res);
                    resolve()
                }).catch((e) => {
                    // console.log(e.response.data);
                    reject(e.response.data);
                })
            })
        },
        //签到
        checkin(ctx,payload){
            return new Promise((resolve, reject)=>{
                axios.post('/checkin', {place: payload.place}).then((rsp)=>{
                    resolve(rsp.data.message);
                }).catch((e)=>{
                    reject(e.response.data.message||'error');
                })
            })
        },
        //获取签到信息
        verifyCheckin(ctx){
            return new Promise((resolve, reject)=>{
                axios.get('/checkin').then((rsp)=>{
                    //ctx.commit('updateStaff', rsp.data.staff);
                    resolve(rsp.data);
                }).catch((e)=>{
                    reject(e.response.data);
                })
            })
        },
        //获取报名信息
        verifyInformation(ctx){
            return new Promise((resolve,reject)=>{
                axios.get('/profile').then((rsp)=>{
                    // console.log(rsp.data);
                    resolve(rsp.data);
                }).catch((e)=>{
                    // console.log(e.response.data);
                    reject(e.response.data);
                })
            })
        },
        getRank(ctx){
            return new Promise((resolve,reject)=>{
                axios.get('/rank').then((rsp)=>{
                    ctx.commit('updateRank',rsp.data.data)
                    resolve(rsp.data);
                    console.log(rsp.data);
                }).catch((e)=>{
                    console.log('失败',e);
                    reject(e)
                })
            })
        },
        getStepRank(ctx){
            return new Promise((resolve,reject)=>{
                axios.get('/step_rank').then((rsp)=>{
                    // ctx.commit('updateStepRank',rsp.data.data)
                    resolve(rsp.data);
                    // console.log(rsp.data);
                }).catch((e)=>{
                    console.log('失败',e);
                    reject(e)
                })
            })
        },
        getScoreRank(ctx){
            return new Promise((resolve,reject)=>{
                axios.get('/score_rank').then((rsp)=>{
                    // ctx.commit('updateStepRank',rsp.data.data)
                    resolve(rsp.data);
                    // console.log(rsp.data);
                }).catch((e)=>{
                    console.log('失败',e);
                    reject(e)
                })
            })
        },
        getAlbum(ctx,payload){
            return new Promise((resolve,reject)=>{
                axios.get('/album?o='+payload.order+'&p='+payload.page).then((rsp)=>{
                    console.log('成功',rsp.data);
                    resolve(rsp.data);
                }).catch((e)=>{
                    console.log('失败',e);
                    reject(e)
                })
            })
        },
        isPhotoLiked(ctx,payload){
            return new Promise((resolve, reject)=>{
                axios.get('/like?album='+payload).then((rsp)=>{
                    resolve(rsp.data);
                }).catch((e)=>{
                    reject(e);
                })
            })
        },
        likePhoto(ctx, payload){
            return new Promise((resolve, reject)=>{
                axios.post('/like', {album_id: payload.album_id}).then((rsp)=>{
                    resolve(rsp.data);
                }).catch((e)=>{
                    reject(e);
                })
            })
        },
        getPlaceById(ctx, id){
            for(let i=0;i<ctx.state.checkPoints.length;i++){
                if(ctx.state.checkPoints[i].id === id){
                    return ctx.state.checkPoints[i];
                }
            }
            return undefined;
        },
        getPlaceByName(ctx, name){
            for(let i=0;i<ctx.state.checkPoints.length;i++){
                if(ctx.state.checkPoints[i].name === name){
                    return ctx.state.checkPoints[i];
                }
            }
            return undefined;
        }
    },
    mutations: {
        placeSelected(state, payload){
            state.staff.place = payload
        },
        setPageTitle(state,payload){
            state.pageTitle = payload
        },
        staffUseridEmailUpdated(state,payload) {
            state.staff.userId = payload.userId
            state.staff.email = payload.email
        },
        staffSelfGiftUpdated(state,payload) {
            state.staff.name = payload.name
            state.staff.idCard = payload.idCard
            state.staff.gift = payload.gift
            state.staff.isEntourage = payload.isEntourage
        },
        staffEntourageUpdated(state,payload){
            state.staff.entourageItems = payload.entourageItems
        },
        updateRank(state,payload){
            state.rankInfo = payload
        },
        updateStepRank(state,payload){
            state.rankInfoStep = payload
        },
        // updateStaff(state, payload){
        //     state.staff = payload
        // }
    },
}
