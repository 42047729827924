<template>
  <div id="app">
<!--    <div class="app_img_box"><img class="app_logo" src="./image/logo.png" alt=""></div>-->

    <div id="app_inner">
      <router-view/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
body{
  margin:0;
  padding:0;
  /*background-color:rgba(31, 37, 31,0.6);*/
}
a{ color: #B91C1C}
#app{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: center;*/
  position: relative;
}
#app_inner{
  width: 750px;
  height: 100%;
}

.app_img_box{
  position: absolute;
  top: 0;
  width: 750px;
  padding: 31px 0 0 36px;
  box-sizing: border-box;
}
.app_logo{
  width:74px;
  height:102px;
}
</style>
