import Vue from 'vue'
import App from './App.vue'

import router from "./router";
import 'lib-flexible'
import store from './store/index'

import 'vue-area-linkage/dist/index.css';
import {AreaCascader} from 'vue-area-linkage';
import {AreaSelect} from 'vue-area-linkage';


Vue.use(AreaCascader)
Vue.use(AreaSelect)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
