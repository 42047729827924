import Vue from 'vue'
import Vuex from 'vuex'
import passport from './passport'
import register from './register'


Vue.use(Vuex)

export default new Vuex.Store({
    // strict: true,
    modules:{
        passport,
        register
    }
})